body {
	--main-container-width: 63.33333333333333vw; /* 1216px */
	--main-container-height: 37.5vw; /* 720px */

	--nav-button-width: 11.4vw; /* 228px */
	--nav-button-font: 2.5vw; /* 48px */

	--modal-width: 46.25vw; /* 888px */
	--modal-column-width: 14.583333333333334vw; /* 280px */
	--modal-close-button-size: 2.4vw;

	--border-radius: 1.25vw; /* 24px */
	--spacing: 1.6666666666666667vw; /* 32px */

	--header-font-size: 3.3854166666666665vw; /* 65px */
	--card-font-size: 1.25vw; /* 24px */

	--background-chill: radial-gradient(
		circle at center,
		#12227a 40%,
		#15013b,
		#040d21
	);

	--background-hyped: radial-gradient(
		circle at center,
		#abf7f2 40%,
		#e9cffc,
		#ba95dd
	);

	--accent-color: rgb(221, 0, 110);
	--font-color: #ffffff;
	--nav-selected-color: #000000;
	--card-background: rgba(0, 0, 0, 0.2);

	--shadow: 0px 0.4166666666666667vw 0.625vw rgba(0, 0, 0, 0.08);

	--anim-duration: 3.5s;
	--anim2-duration: 4.5s;
	--timing-func: ease-in-out;
}

@font-face {
	font-family: 'Garet';
	src: url('../static/Fonts/GaretVariableCYGX.ttf');
}

@font-face {
	font-family: 'Raleway';
	src: url('../static/Fonts/Raleway-VariableFont_wght.ttf');
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body.hyped {
	--font-color: #000000;
	--card-background: rgba(255, 255, 255, 0.4);
	--nav-selected-color: #ffffff;
	cursor: url(../static/cursor-light.svg), auto;
}

html,
body {
	overflow: hidden;

	font-family: Raleway, sans-serif;
	width: 100vw;
	height: 100vh;

	cursor: url(../static/cursor.svg), auto;
}

.background {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	transition: opacity 0.6s ease-out;
}

.background-chill {
	background-image: var(--background-chill);
	opacity: 1;
}

.background-hyped {
	background-image: var(--background-hyped);
	opacity: 0;
}

.hyped .background-chill {
	opacity: 0;
}

.hyped .background-hyped {
	opacity: 1;
}

.loader {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 6.666666666666667vw;
	height: var(--spacing);
	margin: -1vw 0 0 -3.33vw;
	transform: scaleX(0);
	transform-origin: left;
}

.loader-fill {
	height: 100%;
	width: 100%;
	background: rgba(255, 255, 255, 0.6);
}

.webgl {
	position: fixed;
	top: 0;
	left: 0;
	outline: none;

	transition: transform 0.6s ease-out, filter 0.6s ease-out;
}

.cursor-lamp {
	position: absolute;
	width: 32vw;
	height: 32vw;
	border-radius: 50%;
	top: 3vw;
	left: 16vw;
	cursor: url(../static/lamp.svg), auto;
}

.theme-switcher-container {
	position: absolute;
	left: 50%;
	top: var(--spacing);
	transform: translate(-50%, 0);
	display: flex;
	justify-content: space-between;
	align-items: center;

	font-size: calc(var(--card-font-size) * 1.2);
	font-variation-settings: 'wght' 720;
	color: var(--font-color);
	transition: color 0.6s ease-out;
}

input[type='checkbox'] {
	visibility: hidden;
	position: absolute;
}

.switch {
	margin: 0 calc(var(--spacing) / 2);
}

.switcher {
	background: rgba(255, 255, 255, 0.4);
	border-radius: var(--border-radius);

	width: calc(var(--spacing) * 2);
	height: 0.72vw;
	display: flex;
	align-items: center;
	cursor: url(../static/cursor.svg), auto;
}

body.hyped .switcher {
	cursor: url(../static/cursor-light.svg), auto;
}

body.hyped .switcher:before,
body.hyped .nav-button,
body.hyped .modal-close-button,
body.hyped .link {
	cursor: url(../static/pointer-light.svg), auto;
}

.switcher:before {
	content: '';
	width: var(--spacing);
	height: var(--spacing);
	background: black;

	border-radius: 50%;
	cursor: url(../static/pointer.svg), auto;
}

input[type='checkbox']:checked + .switcher:before {
	transform: translate(var(--spacing), 0);
}

.main-container {
	position: relative;
	width: var(--main-container-width);
	height: var(--main-container-height);
	margin: calc((100vh - var(--main-container-height)) / 2) auto;
	overflow: hidden;
}

nav {
	height: var(--main-container-height);
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding-top: calc(var(--spacing) * 2);
	padding-left: var(--spacing);
}

.nav-button {
	width: fit-content;
	width: -moz-fit-content;
	position: relative;
	cursor: url(../static/pointer.svg), auto;
	font-size: 0;
}

.nav-button > span {
	color: var(--font-color);
	font-size: var(--nav-button-font);
	font-variation-settings: 'wght' 720;
	display: inline-block;
	transition: color 0.4s ease-out;
	line-height: var(--nav-button-font);
}

.nav-button-about {
	transition: transform 0.8s ease-out;
	left: 8vw;
	transform: translateX(0);
	text-align: right;
	width: 7.2vw;
}

.nav-button-about:hover .about-anim {
	animation-name: about-anim;
	animation-duration: 2.6s;
	animation-iteration-count: infinite;
	animation-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
	transform-origin: bottom;
}

@keyframes about-anim {
	0% {
		transform: scale(1, 1) translateY(0);
	}
	10% {
		transform: scale(1, 1) translateY(0);
	}
	30% {
		transform: scale(0.9, 1.1) translateY(-0.8vw);
	}
	40% {
		transform: scale(1, 1) translateY(-0.8vw) rotate(-15deg);
	}
	47% {
		transform: scale(1, 1) translateY(-0.8vw) rotate(8deg);
	}
	54% {
		transform: scale(1, 1) translateY(-0.8vw) rotate(-5deg);
	}
	64% {
		transform: scale(1.2, 0.8) translateY(0);
	}
	70% {
		transform: scale(1, 1) translateY(0);
	}
	100% {
		transform: scale(1, 1) translateY(0);
	}
}

.nav-button-projects {
	transition: transform 0.8s ease-out;
	left: 46vw;
	transform: translateX(0);
	text-align: center;
	width: 7.09vw;
}

.nav-button-projects:hover span {
	animation-name: work-anim;
	animation-duration: 1.4s;
	animation-iteration-count: infinite;
	animation-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
	display: inline-block;
	transform-origin: center;
}

.nav-button-projects:hover span:nth-child(even) {
	animation-delay: 0.7s;
}

@keyframes work-anim {
	0% {
		transform: scale(1, 1) translateY(0);
	}
	10% {
		transform: scale(1, 1) translateY(0);
	}
	30% {
		transform: scale(0.9, 1.1) translateY(-0.8vw);
	}
	40% {
		transform: scale(1, 1) translateY(0);
	}
	47% {
		transform: scale(1, 1) translateY(-0.2vw);
	}
	54% {
		transform: scale(1, 1) translateY(-0.2vw);
	}
	64% {
		transform: scale(1, 1) translateY(0);
	}
	100% {
		transform: scale(1, 1) translateY(0);
	}
}

.nav-button-contact {
	transition: transform 0.8s ease-out;
	left: 14vw;
	transform: translateX(0);
	width: 9.6875vw;
}

.contact-anim {
	overflow: hidden;
	vertical-align: bottom;
	width: 5.2vw;
	border-right: 0.28vw solid;
	border-color: transparent;
}

.nav-button-contact:hover .contact-anim {
	animation: contact-anim 1.2s steps(4), blink-caret 0.75s step-end infinite;
	border-color: var(--font-color);
}

@keyframes contact-anim {
	from {
		width: 0;
	}
	to {
		width: 5.2vw;
	}
}

@keyframes blink-caret {
	from,
	to {
		border-color: transparent;
	}
	50% {
		border-color: var(--nav-button-font);
	}
}

.modal {
	position: absolute;
	top: 0;
	left: calc(var(--main-container-width) + var(--spacing));

	height: var(--main-container-height);
	width: var(--modal-width);
	transform: translateX(0);
	transition: transform 0.8s ease-out;

	font-size: var(--card-font-size);
	padding: 0 var(--spacing);
}

.modal-header {
	display: flex;
	align-items: center;
	margin-bottom: 3.0208333333333335vw;
}

.modal-header-text {
	font-size: var(--header-font-size);
	font-variation-settings: 'wght' 375;
	padding-right: calc(var(--spacing) / 2);
	color: var(--font-color);
	transition: color 0.6s ease-out;
}

.modal-header-line {
	flex-grow: 1;
	background-image: url(../static/waves.svg);
	background-size: contain;
	background-repeat: repeat-x;
	height: 1.5vw;
	clip-path: inset(0 100% 0 0);
	transition: clip-path 0.6s ease-in 0.8s;
	opacity: 0.6;
}

.modal-close-button {
	width: var(--modal-close-button-size);
	height: var(--modal-close-button-size);
	font-size: var(--card-font-size);
	line-height: var(--card-font-size);
	border-radius: 50%;
	cursor: url(../static/pointer.svg), auto;
	border: 2px solid var(--font-color);
	vertical-align: middle;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--font-color);
	margin-left: calc(var(--spacing) / 2);
}

.modal.active {
	transform: translateX(
		calc(var(--modal-column-width) - var(--main-container-width))
	);
}

.modal-opened .cursor-lamp {
	display: none;
}

.modal-opened .nav-button-about {
	transform: translateX(-8vw);
}

.modal-opened .nav-button-projects {
	transform: translateX(-46vw);
}

.modal-opened .nav-button-contact {
	transform: translateX(-14vw);
}

.modal.active .modal-header-line {
	clip-path: inset(0 0 0 0);
}

.modal-opened .nav-button.selected {
	pointer-events: none;
}

.modal-opened .nav-button.selected span {
	color: var(--nav-selected-color);
}

.modal-opened .webgl {
	transform: translateX(-50vw) scale(1.5);
	filter: blur(0.4vw);
}

.modal-about-card {
	display: inline-block;

	width: calc(var(--modal-column-width) * 2);
	background: var(--card-background);
	transition: background 0.6s ease-out;

	color: var(--font-color);
	border-radius: var(--border-radius);
	box-shadow: var(--shadow);
	padding: var(--spacing);
}

.modal-about-card p {
	margin-bottom: calc(var(--spacing) / 2);
}

.modal-about-card .link {
	margin-top: var(--spacing);
	display: inline-block;
	color: var(--font-color);
}

.modal-about-photo {
	width: calc(var(--modal-column-width) / 2);
	height: calc(var(--modal-column-width) / 2);
	background: url(../static/didi.png);
	background-size: contain;
	border-radius: var(--border-radius);

	display: inline-block;
	margin-left: var(--spacing);

	vertical-align: top;
	box-shadow: var(--shadow);
}

.modal-projects {
	padding: 0 var(--spacing);
}

.modal-projects > .modal-header {
	margin-bottom: 1.3541666666666667vw;
}

.modal-projects-cards-container {
	overflow-x: scroll;
	padding: var(--spacing) 0;
	scroll-behavior: smooth;
}

.modal-projects-cards-scroll {
	width: calc(var(--modal-column-width) * 4 + var(--spacing) * 4);
}

::-webkit-scrollbar {
	width: 0.52vw;
	height: 0.76vw;
}

::-webkit-scrollbar-track {
	border-radius: 0.52vw;
	background: rgba(255, 255, 255, 0.4);
}

::-webkit-scrollbar-thumb {
	border-radius: 0.52vw;
	background-color: black;
}

.modal-projects-card {
	width: var(--modal-column-width);
	height: 22.8vw;
	background: var(--card-background);
	transition: background 0.6s ease-out, color 0.6s ease-out;
	color: var(--font-color);
	border-radius: var(--border-radius);

	padding: var(--spacing);
	margin: 0 var(--spacing) var(--spacing) 0;

	display: inline-block;
	vertical-align: top;
	box-shadow: var(--shadow);
	position: relative;
}

.modal-projects-card > p {
	margin-bottom: var(--spacing);
}

.modal-projects-card > strong {
	text-align: center;
	display: block;
	width: 100%;

	position: absolute;
	left: 50%;
	bottom: var(--spacing);
	transform: translateX(-50%);
}

.modal-projects-card-site {
	position: absolute;
	left: 50%;
	bottom: var(--spacing);
	transform: translateX(-50%);
	width: 100%;
	text-align: center;
}

.modal-projects-card-title {
	width: 50%;
	font-variation-settings: 'wght' 650;
	font-size: calc(var(--header-font-size) / 2);

	margin-bottom: calc(var(--spacing) / 2);
}

.modal-projects-card-image {
	position: absolute;
	top: -0.8vw;
	right: -0.8vw;
	width: 6vw;
	height: 6vw;
	border-radius: var(--border-radius);
	background-size: contain;
	box-shadow: var(--shadow);
}

.sintez {
	background-image: url(../static/sintez.png);
}

.garet-thumb {
	background-image: url(../static/garet.png);
}

.solar {
	background-image: url(../static/solar.png);
}

.soon {
	background-image: url(../static/soon.png);
}

.modal-contact-card {
	width: calc(var(--modal-column-width) * 2);
	background: var(--card-background);
	transition: background 0.6s ease-out, color 0.6s ease-out;
	color: var(--font-color);
	border-radius: var(--border-radius);

	padding: var(--spacing);
	box-shadow: var(--shadow);
}

.modal-contact-card p {
	margin-bottom: calc(var(--spacing) / 2);
}

.card-contact {
	display: flex;
	align-items: center;
}

.card-contact svg {
	width: 2vw;
	margin-right: 0.8vw;
	fill: var(--font-color);
	transition: fill 0.4s ease-out;
}

a,
.link {
	color: var(--font-color);
	transition: color 0.1s ease-out;
	font-variation-settings: 'wght' 650;
	cursor: url(../static/pointer.svg), auto;
}

.link:hover {
	color: var(--accent-color);
}

::selection {
	background: var(--accent-color);
}

/* Garet */
body.garet {
	overflow-y: scroll;
}

.garet-container {
	width: calc(var(--modal-column-width) * 3);
	margin: calc(var(--spacing) * 4) auto;
	font-size: var(--card-font-size);
}

.back-button {
	position: fixed;
	width: 100%;
	background: #ffffff;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	padding: calc(var(--spacing) / 2);
	box-shadow: var(--shadow);
	z-index: 1000;
}

.back-button > a {
	font-size: calc(var(--card-font-size) * 1.2);
	font-variation-settings: 'wght' 720;
	color: black;
	text-decoration: none;
	width: fit-content;
	width: -moz-fit-content;
}

.back-button > .back-button-text {
	font-size: calc(var(--card-font-size) * 1.2);
}

.back-button-text {
	margin-left: var(--spacing);
}

.garet-header {
	font-size: var(--header-font-size);
	text-align: center;
	margin-bottom: calc(var(--spacing) * 2);
}

.garet-description {
	margin-bottom: var(--spacing);
	line-height: 2vw;
}

.garet-description a.link {
	color: black;
}

.anim1-container,
.anim2-container,
.slides-container {
	width: calc(var(--modal-column-width) * 3);
	height: 32.81249994791667vw;
	background-color: #141414;
	color: #ffffff;
	font-family: 'Garet';
	font-size: 6.822916666666666vw;
	line-height: 6.822916666666666vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: calc(var(--spacing) * 2);
}

.slides {
	width: 100%;
	display: flex;
	position: relative;
	margin-bottom: var(--spacing);
	justify-content: space-around;
}

.slide-layout {
	display: inline-block;
	margin: 0 auto;
}

.anim2-container {
	background-color: #ff3b4e;
}

.anim1-layout {
	width: 25.976561979166668vw;
	display: inline-block;
	margin: 0 auto;
}

.anim2-layout {
	display: inline-block;
	margin: 0 auto;
}

.slide {
	-webkit-appearance: none;
	width: 130px;
	height: 10px;
	border-radius: 4px;
	color: #c1c1c1;
	background: #c1c1c1;
}

input[type='range']::-webkit-slider-runnable-track {
	color: #c1c1c1;
	cursor: url(../static/pointer.svg), auto;
}

input[type='range']::-webkit-slider-thumb {
	-webkit-appearance: none;
	background-color: #ff3b4e;
	width: 18px;
	height: 18px;
	border-radius: 10px;
	overflow: visible;
	cursor: url(../static/pointer.svg), auto;
}

.slide-segment-1 {
	font-variation-settings: 'ital' 0, 'wght' 242;
	transition: font-variation-settings 0.5s ease-in;
}

.slide-segment-2 {
	font-variation-settings: 'wght' 950;
	transition: font-variation-settings 0.5s ease-in;
}

.slide-segment-3 {
	font-variation-settings: 'ital' 0, 'wght' 474;
	transition: font-variation-settings 0.5s ease-in;
}

.slide-segment-4 {
	font-variation-settings: 'ital' 300, 'wght' 500;
	transition: font-variation-settings 0.5s ease-in;
}

.slide-segment-5 {
	font-variation-settings: 'ital' 0, 'wght' 93;
	transition: font-variation-settings 0.5s ease-in;
}

.anim1-segment-1 {
	animation-name: segment-1-anim;
	animation-duration: var(--anim-duration);
	animation-iteration-count: infinite;
	animation-timing-function: var(--timing-func);
}

@keyframes segment-1-anim {
	0% {
		font-variation-settings: 'ital' 0, 'wght' 242.5;
	}
	25% {
		font-variation-settings: 'ital' 0, 'wght' 862.53;
	}
	50% {
		font-variation-settings: 'ital' 100, 'wght' 862.53;
	}
	75% {
		font-variation-settings: 'ital' 100, 'wght' 242.5;
	}
	100% {
		font-variation-settings: 'ital' 0, 'wght' 242.5;
	}
}

.anim1-segment-2 {
	animation-name: segment-2-anim;
	animation-duration: var(--anim-duration);
	animation-iteration-count: infinite;
	animation-timing-function: var(--timing-func);
}

@keyframes segment-2-anim {
	0% {
		font-variation-settings: 'wght' 950;
	}
	25% {
		font-variation-settings: 'wght' 50;
	}
	50% {
		font-variation-settings: 'wght' 950;
	}
	75% {
		font-variation-settings: 'wght' 50;
	}
	100% {
		font-variation-settings: 'wght' 950;
	}
}

.anim1-segment-3 {
	animation-name: segment-3-anim;
	animation-duration: var(--anim-duration);
	animation-iteration-count: infinite;
	animation-timing-function: var(--timing-func);
}

@keyframes segment-3-anim {
	0% {
		font-variation-settings: 'ital' 0, 'wght' 474.07;
	}
	25% {
		font-variation-settings: 'ital' 100, 'wght' 474.07;
	}
	50% {
		font-variation-settings: 'ital' 0, 'wght' 474.07;
	}
	75% {
		font-variation-settings: 'ital' 100, 'wght' 474.07;
	}
	100% {
		font-variation-settings: 'ital' 0, 'wght' 474.07;
	}
}

.anim1-segment-4 {
	animation-name: segment-4-anim;
	animation-duration: var(--anim-duration);
	animation-iteration-count: infinite;
	animation-timing-function: var(--timing-func);
}

@keyframes segment-4-anim {
	0% {
		font-variation-settings: 'ital' 100, 'wght' 826.53;
	}
	25% {
		font-variation-settings: 'ital' 100, 'wght' 356.25;
	}
	50% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	75% {
		font-variation-settings: 'ital' 0, 'wght' 826.53;
	}
	100% {
		font-variation-settings: 'ital' 100, 'wght' 826.53;
	}
}

.anim1-segment-5 {
	animation-name: segment-5-anim;
	animation-duration: var(--anim-duration);
	animation-iteration-count: infinite;
	animation-timing-function: var(--timing-func);
}

@keyframes segment-5-anim {
	0% {
		font-variation-settings: 'ital' 0, 'wght' 93.75;
	}
	25% {
		font-variation-settings: 'ital' 0, 'wght' 950;
	}
	50% {
		font-variation-settings: 'ital' 100, 'wght' 950;
	}
	75% {
		font-variation-settings: 'ital' 100, 'wght' 93.75;
	}
	100% {
		font-variation-settings: 'ital' 0, 'wght' 93.75;
	}
}

.anim2-segment-1 {
	animation-name: anim2-segment-1-anim;
	animation-duration: var(--anim2-duration);
	animation-iteration-count: infinite;
	animation-timing-function: var(--timing-func);
}

@keyframes anim2-segment-1-anim {
	0% {
		font-variation-settings: 'ital' 0, 'wght' 762.96;
	}
	16% {
		font-variation-settings: 'ital' 0, 'wght' 63.75;
	}
	33% {
		font-variation-settings: 'ital' 100, 'wght' 63.75;
	}
	49% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	66% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	83% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	100% {
		font-variation-settings: 'ital' 0, 'wght' 762.96;
	}
}

.anim2-segment-2 {
	animation-name: anim2-segment-2-anim;
	animation-duration: var(--anim2-duration);
	animation-iteration-count: infinite;
	animation-timing-function: var(--timing-func);
	margin-right: -1px;
}

@keyframes anim2-segment-2-anim {
	0% {
		font-variation-settings: 'ital' 100, 'wght' 356.25;
	}
	16% {
		font-variation-settings: 'ital' 100, 'wght' 762.96;
	}
	33% {
		font-variation-settings: 'ital' 0, 'wght' 762.96;
	}
	49% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	66% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	83% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	100% {
		font-variation-settings: 'ital' 100, 'wght' 356.25;
	}
}

.anim2-segment-3 {
	animation-name: anim2-segment-3-anim;
	animation-duration: var(--anim2-duration);
	animation-iteration-count: infinite;
	animation-timing-function: var(--timing-func);
}

@keyframes anim2-segment-3-anim {
	0% {
		font-variation-settings: 'ital' 0, 'wght' 614.81;
	}
	16% {
		font-variation-settings: 'ital' 0, 'wght' 242.5;
	}
	33% {
		font-variation-settings: 'ital' 0, 'wght' 614.81;
	}
	49% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	66% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	83% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	100% {
		font-variation-settings: 'ital' 0, 'wght' 614.81;
	}
}

.anim2-segment-4 {
	animation-name: anim2-segment-4-anim;
	animation-duration: var(--anim2-duration);
	animation-iteration-count: infinite;
	animation-timing-function: var(--timing-func);
}

@keyframes anim2-segment-4-anim {
	0% {
		font-variation-settings: 'ital' 100, 'wght' 474.07;
	}
	16% {
		font-variation-settings: 'ital' 0, 'wght' 826.53;
	}
	33% {
		font-variation-settings: 'ital' 100, 'wght' 614.81;
	}
	49% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	66% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	83% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	100% {
		font-variation-settings: 'ital' 100, 'wght' 474.07;
	}
}

.anim2-segment-5 {
	animation-name: anim2-segment-5-anim;
	animation-duration: var(--anim2-duration);
	animation-iteration-count: infinite;
	animation-timing-function: var(--timing-func);
}

@keyframes anim2-segment-5-anim {
	0% {
		font-variation-settings: 'ital' 0, 'wght' 950;
	}
	16% {
		font-variation-settings: 'ital' 100, 'wght' 356.25;
	}
	33% {
		font-variation-settings: 'ital' 100, 'wght' 474.07;
	}
	49% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	66% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	83% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	100% {
		font-variation-settings: 'ital' 0, 'wght' 950;
	}
}

.anim2-segment-6 {
	animation-name: anim2-segment-6-anim;
	animation-duration: var(--anim2-duration);
	animation-iteration-count: infinite;
	animation-timing-function: var(--timing-func);
}

@keyframes anim2-segment-6-anim {
	0% {
		font-variation-settings: 'ital' 100, 'wght' 155;
	}
	16% {
		font-variation-settings: 'ital' 0, 'wght' 762.96;
	}
	33% {
		font-variation-settings: 'ital' 0, 'wght' 155;
	}
	49% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	66% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	83% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	100% {
		font-variation-settings: 'ital' 100, 'wght' 155;
	}
}

.anim2-segment-7 {
	animation-name: anim2-segment-7-anim;
	animation-duration: var(--anim2-duration);
	animation-iteration-count: infinite;
	animation-timing-function: var(--timing-func);
}

@keyframes anim2-segment-7-anim {
	0% {
		font-variation-settings: 'ital' 100, 'wght' 950;
	}
	16% {
		font-variation-settings: 'ital' 100, 'wght' 474.07;
	}
	33% {
		font-variation-settings: 'ital' 100, 'wght' 242.5;
	}
	49% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	66% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	83% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	100% {
		font-variation-settings: 'ital' 100, 'wght' 950;
	}
}

.anim2-segment-8 {
	animation-name: anim2-segment-8-anim;
	animation-duration: var(--anim2-duration);
	animation-iteration-count: infinite;
	animation-timing-function: var(--timing-func);
	margin-right: -2px;
}

@keyframes anim2-segment-8-anim {
	0% {
		font-variation-settings: 'ital' 0, 'wght' 474.07;
	}
	16% {
		font-variation-settings: 'ital' 0, 'wght' 826.53;
	}
	33% {
		font-variation-settings: 'ital' 0, 'wght' 614.81;
	}
	49% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	66% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	83% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	100% {
		font-variation-settings: 'ital' 0, 'wght' 474.07;
	}
}

.anim2-segment-9 {
	animation-name: anim2-segment-9-anim;
	animation-duration: var(--anim2-duration);
	animation-iteration-count: infinite;
	animation-timing-function: var(--timing-func);
	margin-right: -2px;
}

@keyframes anim2-segment-9-anim {
	0% {
		font-variation-settings: 'ital' 0, 'wght' 762.96;
	}
	16% {
		font-variation-settings: 'ital' 100, 'wght' 762.96;
	}
	33% {
		font-variation-settings: 'ital' 0, 'wght' 242.5;
	}
	49% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	66% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	83% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	100% {
		font-variation-settings: 'ital' 0, 'wght' 762.96;
	}
}

.anim2-segment-10 {
	animation-name: anim2-segment-10-anim;
	animation-duration: var(--anim2-duration);
	animation-iteration-count: infinite;
	animation-timing-function: var(--timing-func);
}

@keyframes anim2-segment-10-anim {
	0% {
		font-variation-settings: 'ital' 0, 'wght' 65;
	}
	16% {
		font-variation-settings: 'ital' 0, 'wght' 950;
	}
	33% {
		font-variation-settings: 'ital' 100, 'wght' 356.25;
	}
	49% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	66% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	83% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	100% {
		font-variation-settings: 'ital' 0, 'wght' 65;
	}
}

.anim2-segment-11 {
	animation-name: anim2-segment-11-anim;
	animation-duration: var(--anim2-duration);
	animation-iteration-count: infinite;
	animation-timing-function: var(--timing-func);
}

@keyframes anim2-segment-11-anim {
	0% {
		font-variation-settings: 'ital' 100, 'wght' 926.53;
	}
	16% {
		font-variation-settings: 'ital' 100, 'wght' 356.25;
	}
	33% {
		font-variation-settings: 'ital' 0, 'wght' 762.96;
	}
	49% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	66% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	83% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	100% {
		font-variation-settings: 'ital' 100, 'wght' 926.53;
	}
}

.anim2-segment-12 {
	animation-name: anim2-segment-12-anim;
	animation-duration: var(--anim2-duration);
	animation-iteration-count: infinite;
	animation-timing-function: var(--timing-func);
}

@keyframes anim2-segment-12-anim {
	0% {
		font-variation-settings: 'ital' 0, 'wght' 762.96;
	}
	16% {
		font-variation-settings: 'ital' 0, 'wght' 826.53;
	}
	33% {
		font-variation-settings: 'ital' 100, 'wght' 950;
	}
	49% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	66% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	83% {
		font-variation-settings: 'ital' 0, 'wght' 356.25;
	}
	100% {
		font-variation-settings: 'ital' 0, 'wght' 762.96;
	}
}

.cs-container {
	display: none;
}

@media (max-width: 786px) {

	/* display nones */
	.cursor-lamp,
	.modal-header-line,
	.card-contact svg,
	.modal-about-photo {
		display: none;
	}


	body {
		--nav-button-font: 32px;
		--main-container-height: 100vh;
		--main-container-width: 100vw;
		--modal-width: 90vw;
		--spacing: 32px;
		--card-font-size: 20px;
		--header-font-size: 32px;
		--modal-close-button-size: 42px;
		--card-background: rgba(0,0,0,0.5);
		}

	body.hyped {
		--card-background: rgba(255,255,255,0.8);
	}

	.main-container {
		margin: 0 auto;
	}

	nav {
		position: relative;
		height: 60vh;
		top: 20vh;
		padding: 0;
	}

	.nav-button {
		width: unset;
		left: unset; right: unset; top: unset; bottom: unset;
		transform: scale(1);
		text-align: center;
		line-height: 56px;
		vertical-align: middle;
	}

	.nav-button > span {
		vertical-align: middle;
	}

	.contact-anim {
		width: unset;
	}

	.modal-opened .nav-button {
		transform: scale(0);
		transition: 0.4s;
	}

	.modal-header {
		margin-bottom: 16px;
		justify-content: space-between;
	}
	
	.modal {
		left: var(--main-container-width);
		top: calc(var(--spacing) * 3);
		padding: 0 0 40px 0;		
		overflow-x: hidden;
		overflow-y: scroll;
		height: 85vh;
	}

	.modal-about-card,
	.modal-contact-card {
		width: 100%;
	}

	.modal.active {
		transform: translateX(-95vw);
	}

	.modal-projects-cards-scroll {
		width: unset;
		height: fit-content;
	}

	.modal-projects-cards-container {
		overflow-x: unset;
		padding: 0;
		height: fit-content;
		padding-bottom: 88px;
	}

	.theme-switcher-container {
		font-size: calc(var(--card-font-size) / 1.2);
		z-index: 1000;
	}

	.modal-projects-card {
		width: unset;
		height: unset;
		margin: 24px 0 0 0;
	}

	.modal-projects-card-site {
		position: unset;
    left: unset;
    bottom: unset;
    transform: unset;
    width: 100%;
	}

	.modal-projects-card > strong {
		position: unset;
		transform: unset;
	}

	.modal-projects-card-title {
		font-size: 24px;
		width: 70%;
	}

	.modal-projects-card-image {
		width: 56px;
		height: 56px;
		top: 32px;
		right: 32px;
	}

	.switcher {
		height: 12px;
	}

	.card-contact a {
		margin: var(--card-font-size) 0;
	}

	/* garet page  */

	.garet-container {
		width: 100vw;
		padding: var(--spacing);
		margin-top: 80px;
	}

	.garet-description {
		line-height: unset;
	}

	.back-button > .back-button-text {
		font-size: var(--card-font-size);
	}

	.back-button > a {
		font-size: var(--card-font-size);
	}
	
	.back-button { 
		height: 80px;
		align-items: center;
	}

	.anim1-container, .anim2-container, .slides-container {
		width: 100%;
		height: 70vw;
		font-size: 56px;
		line-height: 56px;
	}

	.anim1-layout {
		width: 80%;
	}

	.slides {
		display: block;
	}

	.slide {
		width: 100%;
		margin-top: 24px;
	}


}

@media (max-width: 375px) {
	.link {
		font-size: 16px;
	}
} 